'use strict';

Gri.module({
  name: 'list-gamma',
  ieVersion: null,
  $el: $('.list-gamma'),
  container: '.list-gamma',
  fn: function () {
    
  }
});
